import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
Vue.use(ElementUI);

import 'vue-testcase-minder-editor/lib/VueTestcaseMinderEditor.css'
import VueTestcaseMinderEditor from 'vue-testcase-minder-editor'

Vue.use(VueTestcaseMinderEditor)
// console.log('using VueTestcaseMinderEditor', VueTestcaseMinderEditor)

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    caseEditorStore: VueTestcaseMinderEditor.caseEditorStore
  }
})

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
  components: {
    App
  }
}).$mount('#app')
