<template>
  <div id="app">
    <VueTestcaseMinderEditor
      :initJson="initJson"
      ref="minderEditor"
      :allowEditPriority="editMode"
      :allowEditLabel="editMode"
      :allowEditResult="editMode"
      :allowEditNode="editMode"
    >
    </VueTestcaseMinderEditor>

    <el-button
      v-if="$route.query.category==='teacher'"
      type="success"
      :style="{ right: '10px', position: 'absolute', top: '48px' }"
      v-on:click="logCurrentData"
      >保存</el-button
    >
    <el-button
    v-if="$route.query.category==='teacher'"
      :style="{ right: '100px', position: 'absolute', top: '48px' }"
      v-on:click="toggleEditMode"
      >{{ buttonText }}</el-button
    >
  </div>
</template>

<script>
import { postData } from "./axios/api";
import axios from "axios";
import { Loading } from "element-ui";
export default {
  name: "app",
  data() {
    return {
      http: null,
      // initJson: {
      //   root: {
      //     data: {
      //       text: "百度产品",
      //       id: "0",
      //     },
      //     children: [
      //       {
      //         data: { text: "新闻", id: "1", resource: ["自定义标签"] },
      //         children: [
      //           {
      //             data: {
      //               text: "分支主题1",
      //               id: "11",
      //               resource: ["自定义标签"],
      //             },
      //             children: [
      //               {
      //                 data: {
      //                   text: "分支主题1.1",
      //                   id: "13",
      //                   resource: ["自定义标签"],
      //                 },
      //                 children: [
      //                   {
      //                     data: {
      //                       text: "分支主题1.1.1",
      //                       id: "102",
      //                       resource: ["自定义标签"],
      //                     },
      //                   },
      //                 ],
      //               },
      //               {
      //                 data: {
      //                   text: "分支主题1.2",
      //                   id: "103",
      //                   resource: ["自定义标签"],
      //                 },
      //                 children: [
      //                   {
      //                     data: {
      //                       text: "分支主题1.2.1",
      //                       id: "12",
      //                       resource: ["自定义标签"],
      //                     },
      //                   },
      //                 ],
      //               },
      //             ],
      //           },
      //         ],
      //       },
      //       {
      //         data: {
      //           text: "网页",
      //           id: "2",
      //           priority: 1,
      //           resource: ["自定义标签"],
      //         },
      //       },
      //       { data: { text: "贴吧", id: "3", priority: 2 } },
      //       { data: { text: "知道", id: "4", priority: 2 } },
      //       { data: { text: "音乐", id: "5", priority: 3 } },
      //       { data: { text: "图片", id: "6", priority: 3 } },
      //       { data: { text: "视频", id: "7", priority: 3 } },
      //       { data: { text: "地图", id: "8", priority: 3 } },
      //       { data: { text: "百科", id: "9", priority: 3 } },
      //       {
      //         data: {
      //           text: "更多",
      //           id: "10",
      //           hyperlink: "http://www.baidu.com/more",
      //         },
      //       },
      //     ],
      //   },
      // },

      initJson: {
        root: {},
      },

      editMode: false,
    };
  },
  created() {
    this.http = axios.create({
      baseURL: this.$route.query.url?this.$route.query.url:'http://www.test4.36ve.com',
      timeout: 300000, // 请求超时时间
    });
    let loading,
      loadingCount = 0;

    function startLoading() {
      loading = Loading.service({
        lock: true,
        text: "加载中……",
        // background: "red"
        background: "rgba(0, 0, 0, 0.1)",
      });
    }
    function endLoading() {
      loading.close();
    }
    function showLoading() {
      if (loadingCount === 0) {
        startLoading();
      }
      loadingCount += 1;
    }
    function hideLoading(value) {
      if (value == "成功") {
        loadingCount -= 1;
        if (loadingCount === 0) {
          endLoading();
        }
      } else if (value == "网络错误，请检查网络设置！") {
        loadingCount -= 1;
        if (loadingCount === 0) {
          endLoading();
        }
      } else {
        endLoading();
      }
    }

    // 请求拦截器
    this.http.interceptors.request.use(
      (config) => {
        showLoading();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // 响应拦截器
    this.http.interceptors.response.use(
      (response) => {
        if (
          response.data.code != 200 &&
          response.data.code != 404 &&
          response.data.response_code != 200 &&
          response.data.code != 601
        ) {
          hideLoading(response.data.msg);
          return Promise.reject(response.data);
        } else {
          hideLoading("成功");
          // console.log(response);
          return response;
        }
      },
      (error) => {
        hideLoading("网络错误，请检查网络设置！");
        console.log(error);
        // alert('网络错误，请检查网络设置！')
        this.$message.error(error);
        return Promise.reject(error);
      }
    );
  },
  mounted() {
    this.getNodes();
  },
  computed: {
    buttonText: function () {
      return this.editMode === false ? "进入编辑模式" : "退出编辑模式";
    },
  },
  methods: {
    getNodes() {
      this.http
        .get(
          "/index.php/LearningCenter/knowledge-picture/get-mind-mapping?course_id="+this.$route.query.courseid+"&type="+this.$route.query.type+"&bcourseId="+this.$route.query.bcourseId
          // 
          //081622a4-46ee-31b9-a2db-cc5175f149c0
        )
        .then((res) => {
          // console.log(res.data.data);

          this.initJson =res.data.data.initJson
        });
    },
    logCurrentData: function (event) {
      // console.log(this.$refs.minderEditor);
      const caseJson = this.$refs.minderEditor.getJsonData();
      // this.diGui(caseJson.root)
      // console.log(this.diGui(this.initJson,caseJson.root));
      this.http({
        url: "/index.php/LearningCenter/knowledge-picture/update-mind",
        method: "post",
        headers: {'content-type': 'multipart/form-data; boundary=---011000010111000001101001'},
        data:{
          initJson:JSON.stringify({initJson:caseJson}),
          courseId:this.$route.query.courseid,
          type:this.$route.query.type,
          bcourseId:this.$route.query.bcourseId
        }
      }).then((res)=>{
        if(res.data.code=='200'){
          this.$message.success(res.data.msg)
          this.getNodes()
        }
      });
      
      // console.log("编辑器中的最新用例内容：", caseJson);
      const nodeDatas = {};
      this.checkJsonHasDuplicateId(caseJson.root, nodeDatas);
      let hasDuplicateId = false;
      Object.keys(nodeDatas).forEach(function (key) {
        const nodeData = nodeDatas[key];
        if (nodeData.length > 1) {
          console.log("重复id内容: ", nodeData);
          hasDuplicateId = true;
        }
      });
      if (hasDuplicateId) {
        this.$message.error("存在重复 id ，详情请看日志");
      } else {
        // this.$message("未发现重复 id ");
      }
    },
    diGui(beforeArr, afterArr){
      let resObj = {
                insertList: [],
                deleteList: [],
                updateList: [],
                isChange: false,
            };

            let cenMab = new Map();
            //把beforeArr数组去重放入cenObj 
            for (let i = 0; i < beforeArr.length; i++) {
                cenMab.set(JSON.stringify({
                    sysAuthObjTypeId: beforeArr[i].sysAuthObjTypeId,
                    sysAuthDefId: beforeArr[i].sysAuthDefId,
                }), beforeArr[i])
            }
            //遍历afterArr，查看其元素是否在cenObj中
            for (let j = 0; j < afterArr.length; j++) {
                let typeOne = JSON.stringify({
                    sysAuthObjTypeId: afterArr[j].sysAuthObjTypeId,
                    sysAuthDefId: afterArr[j].sysAuthDefId,
                })
                if (cenMab.has(typeOne)) {
                    // console.log(afterArr[j].actionTag );
                    if (cenMab.get(typeOne).actionTag != afterArr[j].actionTag) {
                        resObj.isChange = true;
                        resObj.updateList.push({
                            ...afterArr[j],
                            id: cenMab.get(typeOne).id
                        });
                    }
                    let r1 = cenMab.delete(typeOne)
                } else {
                    resObj.isChange = true;
                    resObj.insertList.push(afterArr[j]);
                }
            }
            // 遍历push剩余删除的数据
            for (let item of cenMab.values()) {
                resObj.isChange = true;
                resObj.deleteList.push(item);
            }
    },
    toggleEditMode: function (event) {
      this.editMode = !this.editMode;
    },
    checkJsonHasDuplicateId: function (jsonData, nodeDatas) {
      // console.log("checkJsonHasDuplicateId", jsonData, nodeDatas)
      let id;
      if (jsonData && jsonData.data && jsonData.data.id) {
        id = jsonData.data.id;
      }
      if (id !== undefined) {
        if (!Object.keys(nodeDatas).includes(id)) {
          nodeDatas[id] = [jsonData.data];
        } else {
          nodeDatas[id].push(jsonData.data);
        }
      }
      if (jsonData.children && jsonData.children.length > 0) {
        jsonData.children.forEach((element) => {
          this.checkJsonHasDuplicateId(element, nodeDatas);
        });
      }
    },
  },
};
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
.font-group,.style-group,.el-select,.result-group,.sequence-group,.edit-del-group,.move-group,.insert-group{
  display: none !important;;
}
#mind_tab-content .mind-tab-panel:first-child .selection-group{
  background: red;
  display: none;
}
</style>