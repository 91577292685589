import axios from "axios";
// import store from "@/store";
import { Loading } from "element-ui";

const service = axios.create({
  baseURL: "http://monitor.36ve.com/index.php/",
  timeout: 300000, // 请求超时时间
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
})
let loading,
  loadingCount = 0;

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: "加载中……",
    // background: "red"
    background: "rgba(0, 0, 0, 0.1)"
  });
}
function endLoading() {
  loading.close();
}
function showLoading() {
  if (loadingCount === 0) {
    //console.log(111111111);
    startLoading();
  }
  loadingCount += 1;
}
function hideLoading(value) {
  if (value == "成功") {
    loadingCount -= 1;
    if (loadingCount === 0) {
      endLoading();
    }
  } else if (value == "网络错误，请检查网络设置！") {
    loadingCount -= 1;
    if (loadingCount === 0) {
      endLoading();
    }
  } else {
    endLoading();
  }
}

// 请求拦截器
service.interceptors.request.use(
  config => {
    showLoading()
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.data.code == 401) {
      localStorage.clear();
    }
    if (response.data.code != 200 && response.data.code != 404 && response.data.response_code != 200 && response.data.code != 601) {
      hideLoading(response.data.msg);
      return Promise.reject(response.data);
    } else {
      hideLoading("成功");
      return response;
    }
  },
  error => {
    hideLoading("网络错误，请检查网络设置！");
    console.log(error);
    // alert('网络错误，请检查网络设置！')
    return Promise.reject(error);
  }
);

export default service
